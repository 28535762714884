<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header col-12">
        <b-breadcrumb class="col-xl-11 m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ isBookingCenter() ? "登記預約中心" : "預約管理" }}
          </b-breadcrumb-item>

          <b-breadcrumb-item v-if="bookingPreset.name" :to="isBookingCenter() ? { name: 'BookingCenterBookingList', params: { id: bookingPreset.id }} : { name: 'BookingList', params: { id: bookingPreset.id }}">
            {{ bookingPreset.name }}
          </b-breadcrumb-item>

          <b-breadcrumb-item active>估價單</b-breadcrumb-item>
        </b-breadcrumb>

        <b-button class="col-xl-1" variant="inverse-info" v-on:click="generatePdf">
          <span class="mdi mdi-printer"></span> 列印
        </b-button>
      </div>
    </div>
    <!-- Page Header End -->

    <div class="container" id="download-pdf">
      <header class="my-4" v-html="bookingPreset.config.quotation_header">
<!--        <div class="row header-div align-items-center">-->
<!--          <div class="col-md-6 text-center">-->
<!--            <img src="https://storage.googleapis.com/waltily-dev/upload_images/HIYEvgCYd2kZz5iC.jpeg" alt="公司 Logo" class="img-fluid">-->
<!--          </div>-->
<!--          <div class="col-md-6" style="text-align: justify;">-->
<!--            <h1 class="h4">金谷旅行社有限公司</h1>-->
<!--            <h2 class="h5">金谷通運股份有限公司</h2>-->
<!--            <p>服務專線：03-5313788 FAX：03-5339149</p>-->
<!--            <p>服務地址：新竹市經國路二段100號8樓812室</p>-->
<!--          </div>-->
<!--        </div>-->
      </header>

      <h3 class="quote-title">估價單</h3>
      <p class="customer-info color-blue">羅技電子</p>

      <table class="quote-table">
        <thead>
        <tr>
          <th>日期</th>
          <th>序號</th>
          <th>內容</th>
          <th>數量</th>
          <th>單位</th>
          <th>單價</th>
          <th>合計</th>
          <th>備註</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="color-blue">4月8日</td>
          <td class="color-blue">1</td>
          <td class="color-blue">08:40 新竹羅技電子 → 台威科技<br>13:30 台灣桃園 → 17:00 回程</td>
          <td class="color-blue">1</td>
          <td class="color-blue">中巴</td>
          <td class="color-blue">10,500</td>
          <td>10,500</td>
          <td></td>
        </tr>
        <tr>
          <td colspan="5">此報價含(路.停車費.司機小費.乘客險.誤餐費)</td>
          <td>總計</td>
          <td>10,500</td>
          <td>(<span class="color-blue">含</span>稅)</td>
        </tr>
        </tbody>
      </table>

      <footer v-html="bookingPreset.config.quotation_footer">
<!--        <div class="row align-items-center" style="width: 100%;margin-bottom: 20px;">-->
<!--          <div class="col-md-6">-->
<!--            <p>金谷運通股份有限公司</p>-->
<!--            <p>統一編號：47126655</p>-->
<!--            <p>經辦人：盧郁潔</p>-->
<!--            <p>電話：03-5313788</p>-->
<!--            <p>傳真：03-5339149</p>-->
<!--            <p>地址：300新竹市經國路二段100號8樓812室</p>-->
<!--          </div>-->
<!--          <div class="stamp col-md-6" style="text-align: center">-->
<!--            <img src="stamp-placeholder.png" alt="公司印章">-->
<!--          </div>-->
<!--        </div>-->

<!--        <div>-->
<!--          <p>款項如匯款或支票付款，請參考下列資料：</p>-->
<!--          <p>戶名：金谷通運股份有限公司</p>-->
<!--          <p>帳號：313-10-059599</p>-->
<!--          <p>銀行：第一銀行竹北分行</p>-->
<!--          <p>銀行代號：007</p>-->
<!--          <p>支票抬頭：金谷通運股份有限公司</p>-->
<!--        </div>-->
      </footer>
    </div>
  </div>
</template>

<script>
import {zh} from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import bookingApi from "@/apis/booking";
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import _ from "lodash";
import {mapGetters} from "vuex";
import collectionApi from "@/apis/collection";
import html2pdf from "html2pdf.js/src";

export default {
  components: {
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  data() {
    return {
      consts,
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      showAddStaff: false,
      step: '',
      serviceOptions: [],
      bookingPreset: {
        name: null,
        config: {
          fields: [],
          quotation_header: null,
          quotation_footer: null,
        }
      },
      start_at: null,
      end_at: null,
      inputForm: {},
      booking: {
        customer_id: null,
        service_id: [],
        staff_id: null,
        branch_id: null,
        start_at: null,
        end_at: null,
        data: []
      },
      customer: null,
      disabledBranchSelect: false
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("route", ["routeQuery", "routeParams"]),
    ...mapGetters('general', [
      'myBranches',
    ]),
  },
  methods: {
    escapeHtml(html) {
      return `<pre><code>${html}</code></pre>`;
    },
    deepGet: _.get,
    async generatePdf(){
      const element = document.getElementById('download-pdf'); // 選擇要匯出的區塊
      const options = {
        filename: 'exported.pdf', // PDF 檔名
        html2canvas: { scale: 2,useCORS: true }, // 提高解析度
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' } // PDF 格式
      };

      html2pdf().set(options).from(element).save();
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    isBookingCenter() {
      return this.$route.name.includes("BookingCenter");
    },
    async init () {
      this.bookingPreset.id = this.$route.params.id;
      await this.fetchBookingPreset();
      this.isEditing = true;
      this.booking.id = this.$route.params.booking_id;
      await this.fetchBooking();
      if (!this.checkPermission([this.consts.BOOKING_MANAGE_BRANCH, this.BOOKING_LIST_QUOTATION])) {
        this.disabledBranchSelect = true;
      }
    },
    async fetchBookingPreset() {
      if (! this.bookingPreset.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await bookingApi.getBookingPreset(this.bookingPreset.id);

        if (! response.data.data.config) {
          response.data.data.config = {
            fields: [],
          };
        }

        this.bookingPreset = response.data.data;

        if (!this.bookingPreset.config?.fields || this.bookingPreset.config.fields.length === 0) {
          this.$swal("提醒", "此預約尚未設定欄位，須設定欄位後才能新增及編輯預約", "warning");
          this.cancel();
          return;
        }

        this.bookingPreset.config.fields = this.bookingPreset.config.fields.map((field) => {
          return {
            id: field._id ?? field.config._id, // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
            ...field,
            ...field.config,
          }
        });

        if (this.bookingPreset.config?.service?.collection_id) {
          const fieldId = this.bookingPreset.config?.service?.title_item_id;
          const collectionData = await collectionApi.getCollection(this.bookingPreset.config.service.collection_id);
          const items = collectionData.data.data.items
          if (items) {
            this.serviceOptions = items.map((item) => {
              return {
                value: item.id,
                text: item.data.find((d) => d.field_id == fieldId)?.value,
              }
            })
          }
        }

        let editInputForm = {};

        for (let i = 0; i < this.bookingPreset.config.fields.length; i ++) {
          const field = this.bookingPreset.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
          editInputForm[fieldId] = null;
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBooking() {
      try {
        this.showLoading = true;
        const response = await bookingApi.getBooking(this.bookingPreset.id, this.booking.id);
        this.booking = response.data;

        let editInputForm = {};

        for (let i = 0; i < this.bookingPreset.config.fields.length; i ++) {
          const field = this.bookingPreset.config.fields[i];
          const fieldId = _.get(field, '_id') ?? _.get(field, 'config._id'); // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
          const fieldData = this.booking.data.find((data) => data.field_id === fieldId)?.value ?? null;

          if (field.type === "checkbox") {
            editInputForm[fieldId] = Array.isArray(fieldData) ? fieldData : [fieldData].filter(Boolean);
          } else {
            editInputForm[fieldId] = fieldData;
          }
        }

        this.start_at = this.booking.start_at;
        this.end_at = this.booking.end_at;
        this.customer = this.booking.customer;

        if (this.booking.service_id) {
          this.booking.service_id = JSON.parse(this.booking.service_id)
        } else {
          this.booking.service_id = []
        }

        this.inputForm = editInputForm;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.header-div {
  width: 100%
}

.container {
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

header {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.logo img {
  width: 100px;
  height: auto;
}

.company-info {
  margin-left: 20px;
}

.company-info h1, .company-info h2 {
  margin: 0;
  font-size: 20px;
}

.quote-title {
  text-align: center;
  font-size: 24px;
  margin: 10px 0;
}

.color-blue {
  color: cornflowerblue;
}
.customer-info {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}

.quote-table {
  width: 100%;
  border-collapse: collapse;
}

.quote-table th, .quote-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.quote-table th {
  background-color: #f4f4f4;
}

.total {
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

footer {
  padding-top: 30px;
  text-align: left;
  margin-left: 30px;
}

.stamp img {
  width: 100px;
  height: auto;
  margin-top: 10px;
}

.img-fluid {
  width: 100px;
  height: 50px;
}

.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon-for-done {
    display: none;
  }

  &.done {
    i {
      color: green;
    }

    .icon-for-done {
      display: unset;
    }

    .icon-for-undone {
      display: none;
    }
  }
}
</style>
